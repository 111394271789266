//import logo from './logo.svg';
import logo from './logoheader.jpg';
import slogan from './slogen.jpg';
import './App.css';
import * as XLSX from 'xlsx';
import { useState } from "react";

function App() {
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         {/* HỌC VIÊN Y DƯỢC HỌC CỔ TRUYỀN */}
  //         XÁC ĐỊNH QUY KINH CỦA VỊ THUỐC NAM
  //       </p>
  //       <h3> Nhập file đầu vào tính toán </h3>

  //       <form className="form-group custom-form">
  //         <input
  //           type="file"
  //           onInput={_handleUpload}
  //         />
  //         <button type="submit" className="btn btn-success btn-md">UPLOAD</button>
  //       </form>
        
  //       <div className="viewer">View Data</div>
  //     </header>
  //   </div>
  // );
  // onchange states
  const [excelFile1, setExcelFile1] = useState(null);
  const [excelFile2, setExcelFile2] = useState(null);
  const [typeError, setTypeError] = useState(null);
  const [rawInputData, setRawInputData] = useState(null);  // List các dữ liệu tên vị thuốc của từng đường kinh trong file excel Input gốc
  const [quyKinhData, setQuyKinhData] = useState(null);  // Dữ liệu quy kinh đã tính toán ở file excel
  const [nguongRangBuoc, setNguongRangBuoc] = useState(null);  // Dữ liệu ngưỡng (tính ra trong file excel gốc) và ràng buộc cho sẵn trong file gốc

  const listKinh = ['KINH TÂM', 'KINH CAN', 'KINH TỲ', 'KINH PHẾ', 'KINH THẬN', 'KINH ĐỞM', 'ĐẠI TRƯỜNG', 'TIỂU TRƯỜNG', 'BÀNG QUANG'];
  const listCum = ['Cụm Tâm 1', 'Cụm Can 1', 'Cụm Tỳ 1', 'Cụm Phế 1', 'Cụm Thận 1', 'Cụm Đởm 1', 'Cụm Đại trường 1', 'Cụm Tiểu trường 1', 'Cụm Bàng quang 1'];

  // submit state
  const [excelData1, setExcelData1] = useState(null);
  const [excelData2, setExcelData2] = useState(null);

  // onchange event
  const handleFile1=(e)=>{
    let fileTypes = ['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','text/csv'];
    let selectedFile = e.target.files[0];
    if(selectedFile){
      if(selectedFile&&fileTypes.includes(selectedFile.type)){
        setTypeError(null);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload=(e)=>{
          setExcelFile1(e.target.result);
        }
      }
      else{
        setTypeError('Hãy chọn đúng file Excel!');
        setExcelFile1(null);
      }
    }
    else{
      console.log('Please select your file');
    }
  }

  const handleFile2=(e)=>{
    let fileTypes = ['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','text/csv'];
    let selectedFile = e.target.files[0];
    if(selectedFile){
      if(selectedFile&&fileTypes.includes(selectedFile.type)){
        setTypeError(null);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload=(e)=>{
          setExcelFile2(e.target.result);
        }
      }
      else{
        setTypeError('Hãy chọn đúng file Excel!');
        setExcelFile2(null);
      }
    }
    else{
      console.log('Please select your file');
    }
  }

  // submit event
  const handleFileSubmit1=(e)=>{
    e.preventDefault();
    if(excelFile1!==null){
      const workbook = XLSX.read(excelFile1,{type: 'buffer'});
      //Lấy dữ liệu tên vị thuốc của từng đường kinh trong file excel Input gốc
      var rawInput = [];
      //const worksheet0 = workbook.Sheets[workbook.SheetNames[0]];
      // for (let row = 0; row < 12; row += 1) {
      //   const worksheet0 = workbook.Sheets[workbook.SheetNames[row]];
      //   const data0 = XLSX.utils.sheet_to_json(worksheet0, {header: 2, raw: true, blankrows: true, skipHidden: true});
      //   console.log("data0=" + data0);
        // for (let dataIndex = 0; dataIndex < data0.length; dataIndex += 1) {
        //   rawInput[row][listKinh[row]].push(data0[dataIndex]['Ten_thuoc']);
        // }
        //var randomNumBetween12 = Math.floor(Math.random()*9)
        //console.log("rawInput[" + row + "]=" + rawInput[row]);
        //visualData.push({'Tên vị thuốc':data[row][1], "Quy kinh":listKinh[randomNumBetween12], "Theo cụm":listCum[randomNumBetween12], "Tỉ lệ tin cậy (%)":Math.random()*100});
      //}
      setRawInputData(rawInput);
      console.log("rawInputData = " + rawInputData);

      const worksheet1 = workbook.Sheets["SP2.2"];
      //A: Tên đường kinh, B: Cụm quy kinh, C: Biến số liên quan,
      //D: Tần suất (giá trị thực), E: Số thứ tự biến, F: Tần suất xuất hiện (%)
      const data = XLSX.utils.sheet_to_json(worksheet1, {header: 1, raw: true, blankrows: true});
      var visualData = [];
      
      //Day du lieu vao array
      for (let row = 1; row < data.length; row += 1) {  // row = 0 ứng với Header
        visualData.push({'Cụm quy kinh': data[row][1], "Biến số liên quan": data[row][2], "Tần suất xuất hiện (%)": data[row][5]});
      }
      // for (let dataIndex = 0; dataIndex < data.length; dataIndex += 1) {
      //   console.log("data[" + dataIndex + "]=" + data[dataIndex]);
      // }
      //setQuyKinhData(XLSX.utils.sheet_to_json(worksheet1, {header: ['A', 'D', 'E'], raw: true, blankrows: true}));
      
      //const worksheet2 = workbook.Sheets["Nguong_RangBuoc"];
      //A: Số biến của cụm quy kinh, B: Giá trị ngưỡng, C: Đường kinh
      //setNguongRangBuoc(XLSX.utils.sheet_to_json(worksheet2));
      //console.log("Data >>> " + data);
      setExcelData1(visualData.slice(0,visualData.length));
    }
  }

  const handleFileSubmit2=(e)=>{
    e.preventDefault();
    if(excelFile2!==null){
      const workbook = XLSX.read(excelFile2,{type: 'buffer'});
      
      const worksheet2 = workbook.Sheets["SP4"];
      //A: Tên vị thuốc, B: Quy kinh, C: Theo cụm, D: Tỉ lệ tin cậy (%)
      const data = XLSX.utils.sheet_to_json(worksheet2, {header: 1, raw: true, blankrows: true});
      var visualData = [];
      
      //Day du lieu vao array
      for (let row = 1; row < data.length; row += 1) {  // row = 0 ứng với Header
        visualData.push({'Tên vị thuốc': data[row][0], "Quy kinh": data[row][1], "Theo cụm": data[row][2], "Tỉ lệ tin cậy (%)": data[row][3]});
      }
      
      {/* Từ các dữ liệu đã lấy từ file 1 (calculateData, nguongRangBuoc),
      cùng với dữ liệu của file 2 (data), ta tính ra giá trị dự đoán/quy kinh */}
      
      //Tinh toan gia tri ham nhan dang voi tung vi thuoc
      // for (let row = 2; row < data.length; row += 1) {
      //   var randomNumBetween12 = Math.floor(Math.random()*9)
      //   console.log("randomNumBetween12 >>> " + randomNumBetween12);
      //   visualData.push({'Tên vị thuốc':data[row][1], "Quy kinh":listKinh[randomNumBetween12], "Theo cụm":listCum[randomNumBetween12], "Tỉ lệ tin cậy (%)":Math.random()*100});
      // }

      //var visualData = [{'Tên vị thuốc':'Vị A', "Quy kinh":'Kinh T', "Theo cụm":'Cụm 1', "Tỉ lệ tin cậy (%)":'12.3'}];
      //console.log("visualData >>> " + visualData);
      setExcelData2(visualData.slice(0,visualData.length));
    }
  }

  return (
    <div className="wrapper">

      <header className="app">
         <div>
            <img src={logo}/>
            <img src={slogan}/> 
         </div>
         <h2 className='header'>
           Xây dựng công cụ xác định quy kinh của vị thuốc nam
         </h2>
      </header>

      {/* Khối thứ nhất */}
      <h3>1.Tính toán cụm biến số quy kinh</h3>

      {/* form */}
      <form className="form-group custom-form" onSubmit={handleFileSubmit1}>
        <input type="file" className="form-control" required onChange={handleFile1} />
        <button type="submit" className="btn btn-success btn-md">TÍNH TOÁN</button>
        {typeError&&(
          <div className="alert alert-danger" role="alert">{typeError}</div>
        )}
      </form>

      {/* view data */}
      <div className="viewer">
        {excelData1?(
          <div className="table-responsive">
            <table className="table">

              <thead >
                <tr>
                  {Object.keys(excelData1[0]).map((key)=>(
                    <th className='table-header' key={key}>{key}</th>
                  ))}
                </tr>
              </thead>

              <tbody >
                {excelData1.map((individualExcelData, index)=>(
                  <tr key={index}>
                    {Object.keys(individualExcelData).map((key)=>(
                      <td className='table-cell' key={key}>{individualExcelData[key]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>

            </table>
          </div>
        ):(
          <div>Vui lòng tải file lên!</div>
        )}
      </div>

      
      {/* Khối thứ hai */}
      <h3>2.Dự đoán quy kinh</h3>

      {/* form */}
      <form className="form-group custom-form" onSubmit={handleFileSubmit2}>
        <input type="file" className="form-control" required onChange={handleFile2} />
        <button type="submit" className="btn btn-success btn-md">DỰ ĐOÁN</button>
        {typeError&&(
          <div className="alert alert-danger" role="alert">{typeError}</div>
        )}
      </form>

      {/* view data */}
      <div className="viewer">
        {excelData2?(
          <div className="table-responsive">
            <table className="table">

              <thead>
                <tr>
                  {Object.keys(excelData2[0]).map((key)=>(
                    <th className='table-header' key={key}>{key}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {excelData2.map((individualExcelData, index)=>(
                  <tr key={index}>
                    {Object.keys(individualExcelData).map((key)=>(
                      <td className='table-cell' key={key}>{individualExcelData[key]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>

            </table>
          </div>
        ):(
          <div>Vui lòng tải file lên!</div>
        )}
      </div>

      <p>
        Nhóm tác giả: Phạm Vũ Khánh, Nguyễn Tiến Chung, Nguyễn Thị Lan, Nguyễn Thành Công
      </p>

    </div>
  );
}

export default App;
